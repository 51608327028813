@import "/src/styles/variables.module.scss";
@import "/src/styles/mixins.module.scss";

@font-face {
    font-family: "SF Pro Display";
    src:
        url("./SFPRODISPLAYREGULAR.OTF") format("opentype"),
        url("./SFPRODISPLAYBOLD.OTF") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;
    }
}

html body {
    min-height: 100%;
    margin: 0;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    position: relative;
    height: 100%;
    background: linear-gradient(126deg, #030316 28.82%, #0d0a45 117.87%);
    background-blend-mode: darken;
}

.career {
    background: #010101;
    background-blend-mode: darken;
    position: relative;

    &__back {
        position: absolute;
        width: 100%;
        opacity: 1;
        z-index: 0;

        img {
            width: 100%;
        }

        @include for-phone-only {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 0;
        }
    }

    &__head {
        padding: 40px;
        z-index: 100000;
    }

    &__main {
        display: flex;
        align-items: center;
        flex-direction: column;

        &__title {
            color: #7b33e7;
            text-align: center;
            font-family: "SF Pro Display";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;

            margin: 0;
            margin-bottom: 20px;
        }

        &__subtitle {
            text-align: center;
            font-family: "SF Pro Display";
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: linear-gradient(90deg, #bbb 0%, #848484 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            margin: 0;
            margin-bottom: 20px;
        }

        &__desc {
            color: var(--system-white, #fff);
            text-align: center;
            font-family: "SF Pro Display";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
            margin: 0;

            margin-bottom: 40px;

            width: 40%;

            @include for-phone-only() {
                width: 80%;
            }
        }

        &__content {
            width: 60%;

            @include for-phone-only() {
                width: 90%;
            }

            &__job {
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.03) 100%);
                box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
                backdrop-filter: blur(20px);

                padding: 20px;

                &__title {
                    color: #d2d8e9;
                    font-family: "SF Pro Display";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &__exp {
                    display: flex;
                    flex-direction: column;
                    &__title {
                        color: #bdc8e9;
                        font-family: "SF Pro Display";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    &__details {
                        color: #d2d8e9;
                        font-family: "SF Pro Display";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }
        }
    }
}
