@import "/src/styles/variables.module.scss";
@import "/src/styles/mixins.module.scss";

@font-face {
    font-family: "SF Pro Display";
    src:
        url("./SFPRODISPLAYREGULAR.OTF") format("opentype"),
        url("./SFPRODISPLAYBOLD.OTF") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media (min-width: 900px) and (max-width: 1200px) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin eighteen {
    color: #ccc;
    font-size: 18px;
    font-weight: 400;
}

@mixin twenty {
    color: #ccc;
    font-size: 18px;
    font-weight: 400;
}

.mouse {
    width: 50px;
    height: 90px;
    border: 3px solid #a7b0c8;
    border-radius: 60px;
    position: relative;
    &::before {
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #a7b0c8;
        border-radius: 50%;
        opacity: 1;
        animation: wheel 2s infinite;
        -webkit-animation: wheel 2s infinite;
    }
}

@keyframes wheel {
    to {
        opacity: 0;
        top: 60px;
    }
}

@-webkit-keyframes wheel {
    to {
        opacity: 0;
        top: 60px;
    }
}

.title {
    text-align: center;
    font-size: 53px;
    font-weight: 600;
    color: linear-gradient(90deg, #dadada 0%, #d1d1d1 100%);

    @include for-phone-only {
        font-size: 36px;
    }
}

.subtitle {
    text-align: center;
    color: #cacaca;
    font-size: 28px;
    font-weight: 500;
    margin-top: 24px;

    strong {
        color: #976df0;
        font-size: 28px;
        font-weight: 500;
    }

    @include for-phone-only {
        font-size: 22px;
    }
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    background-color: #000;
    color: #fff;
    font-family: Arial, sans-serif;
}

.layout {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.main {
    flex-grow: 1;

    background-image: url("https://assets.prd.swz-infra.net/assets/hero.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;

    padding: 250px;

    @include for-phone-only {
        color: #ccd6f0;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 24px;

        background-size: cover;
    }

    &__title {
        color: #a7b0c8;
        font-size: 64px;
        font-weight: 600;
        text-align: center;

        @include for-phone-only {
            color: #ccd6f0;
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    &__subtitle {
        color: #976df0;

        font-size: 64px;
        font-weight: 400;

        @include for-phone-only {
            color: #976df0;
            text-wrap: wrap;
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    &__desc {
        color: #c5c5c5;
        font-size: 26px;
        font-weight: 500;
        letter-spacing: -0.13px;
        margin-top: 24px;
        text-align: center;

        @include for-phone-only {
            font-size: 20px;
        }
    }

    &__scroll {
        margin-top: 60px;
    }
}
.home {
    &__header {
        font-size: 20px;
        color: #a7b0c8;
        font-weight: 800;
        align-items: center;
        display: flex;

        img {
            width: 172px;
            height: 84px;
        }
    }
}

.section {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.services {
    opacity: 0;
    // min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;

    background-image: url("https://assets.prd.swz-infra.net/assets/service.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    flex-direction: column;

    margin-top: 50px;

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        // padding: 240px;
        padding-top: 50px;

        @include for-phone-only {
            padding: 24px;
        }

        @media (min-width: 1510px) {
            width: 70%;
        }
    }
}

.service {
    flex: 0 0 calc(50% - 10px); /* Strictly 50% width, minus the gap */
    box-sizing: border-box;

    border-radius: 20px;
    border: 2px solid rgba(102, 102, 102, 0);
    background: linear-gradient(151deg, rgba(44, 43, 43, 0.9) -19.3%, rgba(35, 21, 74, 0.9) 81.94%);
    padding: 44px 30px;

    max-width: 500px;

    // flex: 50%; /* or - flex: 0 50% - or - flex-basis: 50% - */
    /*demo*/
    box-shadow: 0 0 0 1px black;
    margin-bottom: 30px;

    width: 45%;
    height: 300px;

    margin-left: 30px;

    @include for-phone-only {
        margin-left: 0;
        width: 100%;
        height: fit-content;
        min-height: 224px;
        padding: 36px 24px;
        flex: auto;
    }

    &__head {
        color: #dadada;
        font-size: 24px;
        font-weight: 600;

        display: flex;
        align-items: center;

        @include for-phone-only {
            font-size: 18px;
        }

        img {
            @include for-phone-only {
                width: 54px;
            }
        }
    }

    &__content {
        color: #ccc;
        font-size: 18px;
        font-weight: 400;

        margin-left: 16px;

        @include for-phone-only {
            font-size: 12px;
            margin-top: 15px;
        }
    }
}

.products {
    opacity: 0;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    background-image: url("https://assets.prd.swz-infra.net/assets/solution.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;

    flex-direction: column;

    margin-top: 50px;
}

.product {
    display: flex;

    @include for-phone-only {
        flex-direction: column;
    }

    &__reverse {
        display: flex;
        flex-direction: row-reverse;

        @include for-phone-only {
            flex-direction: column;
        }
    }

    &__img {
        width: 35%;
        height: 50%;
        padding: 80px;

        @include for-phone-only {
            width: 80%;
            padding: 16px;
        }
    }

    &__detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 50px;

        &__reverse {
            padding-right: 0;
            padding-left: 50px;

            @include for-phone-only {
                text-align: center;
                padding: 16px;
            }
        }

        @include for-phone-only {
            text-align: center;
            padding: 16px;
        }

        &__title {
            color: #e8e8e8;
            font-size: 32px;
            font-weight: 500;

            strong {
                color: #ab79f6;
            }
        }

        &__desc {
            color: #e2e0e0;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            margin-top: 16px;
        }
    }
}

.footer {
    background: linear-gradient(262deg, #4c3e73 9.33%, #18083e 60.91%);
    padding: 60px 45px;

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            text-align: center;
            font-size: 40px;
            font-weight: 600;

            background: linear-gradient(90deg, #bbb 0%, #848484 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            width: 65%;

            @include for-phone-only {
                font-size: 16px;
            }
        }

        img {
            height: 100px;
            width: 100px;

            @include for-phone-only {
                height: 38px;
                width: 32px;
            }
        }
    }

    &__content {
        display: flex;
        // justify-content: space-between;
        gap: 120px;

        @include for-phone-only {
            gap: 20px;
        }

        &__links {
            display: flex;
            gap: 16px;
            flex-direction: column;
            &__title {
                color: #e2e0e0;
                font-size: 19px;
                font-weight: 700;

                @include for-phone-only {
                    font-size: 14px;
                }
            }

            color: #e2e0e0;
            font-size: 15px;
            font-weight: 500;

            @include for-phone-only {
                font-size: 12px;
            }

            div {
                cursor: pointer;
            }
        }

        &__copy {
            margin-left: auto;
            color: #e2e0e0;

            font-size: 18px;
            font-weight: 400;
            text-transform: capitalize;

            display: flex;
            align-items: flex-end;

            @include for-phone-only {
                font-size: 10px;
            }
        }
    }
}

.investment {
    padding: 50px;
}

.investment__title {
    width: 100%;
    text-align: center;
    color: #a2a2a2;
    font-family: "SF Pro Display";
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 5.4px;

    margin-bottom: 12px;

    word-wrap: break-word;
    overflow-wrap: break-word;
    display: block;
    white-space: normal;
}

.investment__subtitle {
    width: 100%;
    text-align: center;
    color: #a2a2a2;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 5.4px;

    margin-bottom: 12px;
}

.investment__justtitle {
    width: 100%;
    text-align: center;
    color: #a2a2a2;
    font-family: "SF Pro Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 300;

    margin-bottom: 4px;

    word-wrap: break-word;
    overflow-wrap: break-word;
    display: block;
    white-space: normal;
}

.investment__million {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
}

.investment__list {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    td {
        border: 1px solid #eaeaea; /* Border for individual cells */
        padding: 10px; /* Adds padding inside cells */
    }

    th {
        border: 1px solid #eaeaea; /* Border for individual cells */
        padding: 10px; /* Adds padding inside cells */
    }

    table {
        width: 80%;
    }
}

.align__right {
    text-align: right;
}
