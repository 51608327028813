.item {
  width: 100%;
  height: 100vh;
  background: red;
}

body {
  margin: 0;
  padding: 0;
}

.phone-container {
  background-color: black;
  color: white;
}

.phone-container-input {
  background-color: transparent !important;
  color: white;
}

.phone-container-dropdown {
  background-color: transparent !important;
  color: white;
}

.containCard {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swipe {
  width: 100%;
  height: 100%;
  display: flex;
  transition: all 0.5s ease-out;
  flex-direction: column;
  display: flex;
  will-change: transform;

  left: 0;
  overflow-y: scroll;
  position: fixed;
  scrollbar-width: none;
  top: 0;
}

.card {
  background: cyan;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  transform-origin: center top;
}
