@mixin full-height-width {
    height: 100vh;

    // min-height: -webkit-fill-available;
    width: 100%;
}

@mixin full-height {
    height: 100%;
}

@mixin full-width {
    width: 100%;
}

@mixin fit-width {
    width: fit-content;
}

@mixin half-width {
    width: 50%;
}

@mixin width-fit {
    width: fit-content;
}

@mixin select-none {
    user-select: none;
}

@mixin everything-center($direction: row) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: $direction;
}

@mixin flex-direction($direction: row) {
    display: flex;
    flex-direction: $direction;
}

@mixin title {
    font-size: $px32;
    font-style: normal;
    font-weight: $font-medium;
    line-height: normal;
    color: $f7;
}

@mixin sub-title {
    font-size: $px16;
    font-style: normal;
    font-weight: $font-normal;
    line-height: normal;
    color: $accent;
}

//text mixins
@mixin base-text {
    letter-spacing: -0.02em;
    font-style: normal;
}

@mixin text-align {
    text-align: center;
}

@mixin font-3xs($weight: 400) {
    @include base-text;
    font-size: $text-3xs; //8px
    font-weight: $weight;
}

@mixin font-2xs($weight: 400) {
    @include base-text;
    line-height: $px12; //13px;
    font-size: $text-2xs; //10px
    font-weight: $weight;
}

@mixin font-xs($weight: 400) {
    @include base-text;
    line-height: $px16; //16px;
    font-size: $text-xs; //12px
    font-weight: $weight;
}

@mixin font-sm($weight: 400) {
    @include base-text;
    line-height: $px18;
    font-size: $text-sm; //14px
    font-weight: $weight;
}

@mixin font-base($weight: 400) {
    @include base-text;
    line-height: $px20; // 20px;
    font-size: $text-base; //16px
    font-weight: $weight;
}

@mixin font-lg($weight: 400) {
    @include base-text;
    line-height: $px22; // 22px;
    font-size: $text-lg; //18px
    font-weight: $weight;
}

@mixin font-xl($weight: 400) {
    @include base-text;
    line-height: $px26; //24px;
    font-size: $text-xl; //20px
    font-weight: $weight;
}

@mixin font-2xl($weight: 400) {
    @include base-text;
    line-height: $px28; //28px;
    font-size: $text-2xl; //22px
    font-weight: $weight;
}

@mixin font-3xl($weight: 400) {
    @include base-text;
    line-height: $px30; //40px;
    font-size: $text-3xl; //24px
    font-weight: $weight;
}

@mixin font-4xl($weight: 400) {
    @include base-text;
    line-height: $px40; //40px;
    font-size: $text-4xl; //30px
    font-weight: $weight;
}

@mixin font-5xl($weight: 400) {
    @include base-text;
    line-height: $px40; //40px;
    font-size: $text-5xl; //32px
    font-weight: $weight;
}

@mixin backdrop {
    backdrop-filter: blur(50px);
}

// @mixin cursor-pointer($type: pointer) {
// 	cursor: pointer;
// 	-webkit-tap-highlight-color: transparent;
// }

// @mixin scrollbar($type: pointer) {
// 	::-webkit-scrollbar {
// 		width: $px2;
// 		height: 15px;
// 		border-radius: $px2;
// 	}

// 	/* Track */
// 	::-webkit-scrollbar-track {
// 		background: $black-bt-75;
// 	}

// 	/* Handle */
// 	::-webkit-scrollbar-thumb {
// 		background: $black-bt-75;
// 	}

// 	/* Handle on hover */
// 	::-webkit-scrollbar-thumb:hover {
// 		background: $black-bt-75;
// 	}
// }

@mixin pointer {
    cursor: pointer;
}

@mixin border($color: $f2) {
    border: 1px solid $color;
}

@mixin border-bottom($color: $f2) {
    border-bottom: 1px solid $color;
}

@mixin border-top($color: $f2) {
    border-top: 1px solid $color;
}

@mixin skeleton {
    border-radius: $px50;
    background: linear-gradient(90deg, $f2 25%, $f10 50%, $f2 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media (min-width: 900px) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}

//animations

@mixin slide-animation {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(500px);
        transform: translateY(500px);
    }
}

@mixin zindex-1200 {
    z-index: 1200;
}

@mixin zindex-1100 {
    z-index: 1100;
}

@mixin zindex-1000 {
    z-index: 1000;
}

@mixin stick-to-bottom {
    position: absolute;
    bottom: 0;

    @include for-phone-only {
        position: fixed;
    }

    // @include for-desktop-up {
    //     width: $regular-desktop-width;
    // }

    // @include for-tablet-portrait-up {
    //     width: $regular-desktop-width;
    // }

    // @include for-tablet-landscape-up {
    //     width: $regular-desktop-width;
    // }
}

@mixin desktop-width {
    @include for-desktop-up {
        width: $regular-desktop-width;
    }

    @include for-tablet-portrait-up {
        width: $regular-desktop-width;
    }

    @include for-tablet-landscape-up {
        width: $regular-desktop-width;
    }
}

@mixin other-than-phone {
    @include for-desktop-up {
        @content;
    }

    @include for-tablet-portrait-up {
        @content;
    }

    @include for-tablet-landscape-up {
        @content;
    }
}
